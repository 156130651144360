import Swiper, { Navigation } from "swiper";

function slideApproach() {
  new Swiper("#approach .swiper", {
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
  });
}

function slideValues() {
  new Swiper("#values .swiper", {
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
  });
}

function slideNews() {
  new Swiper("#news .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: "#news .swiper-button-next",
      prevEl: "#news .swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
    },
  });
}

function slideExplore() {
  new Swiper('#explore .swiper', {
    modules: [Navigation],
    slidesPerView: 'auto',
    spaceBetween: 32,
    breakpoints: {
      768: {
        spaceBetween: 28,
      },
    },
    navigation: {
      prevEl: '#explore .swiper-button-prev',
      nextEl: '#explore .swiper-button-next',
    },
  })
}

export { slideApproach, slideValues, slideNews, slideExplore };
