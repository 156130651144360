import { slideApproach, slideValues, slideNews, slideExplore } from "@modules/slide";
import { toggleNav, openVideo, accordion, modalTarget, openExploreVideo } from "@modules/tricks";
import textSplit from "@modules/text-split";
import { heroAnimation } from "@ui/animations";

export default [
  {
    namespace: "home",
    beforeEnter() {
      toggleNav();
      textSplit();
      heroAnimation();
      openVideo();
      slideApproach();
      slideValues();
      slideNews();
      accordion();
      modalTarget();
      slideExplore();
    },
    afterEnter() {
      openExploreVideo();
    }
  },
  {
    namespace: "single",
    beforeEnter() {
      toggleNav();
      textSplit();
      modalTarget();
    },
  },
  {
    namespace: "single-post",
    beforeEnter() {
      toggleNav();
      textSplit();
      modalTarget();
    },
  },
  {
    namespace: "page",
    beforeEnter() {
      toggleNav();
      textSplit();
      modalTarget();
    },
  },
  {
    namespace: "error404",
    beforeEnter() {
      toggleNav();
      textSplit();
      modalTarget();
    },
  },
  {
    namespace: "page-templates/beta",
    beforeEnter() {
      textSplit();
      openVideo();
    },
  },
];
