import lottie from "lottie-web";

function heroAnimation() {
  lottie.loadAnimation({
    container: document.getElementById("pedals"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/rennsport/dist/data/pedals.json",
  });

  lottie.loadAnimation({
    container: document.getElementById("g-force"),
    renderer: "svg",
    loop: true,
    autplay: true,
    path: "/wp-content/themes/rennsport/dist/data/g-force.json",
  });
}

export { heroAnimation };
