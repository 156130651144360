import $ from "jquery";
import { instance } from "@ui/site-scroll";

function toggleNav() {
  $(".navbar .toggle").on("click", function () {
    $("body").toggleClass("menu-open");
  });

  $(".navbar .menu a").on("click", function () {
    $("body").removeClass("menu-open");
  });
}

function openVideo() {
  function playVideo() {
    $(".video-modal video").get(0).play();
  }

  function pauseVideo() {
    $(".video-modal video").get(0).pause();
    setTimeout(function () {
      $(".video-modal video").get(0).currentTime = 0;
    }, 500);
  }

  $(".hero").on("click", function () {
    $("body").addClass("open-video");
    playVideo();
  });

  function closeVideo() {
    pauseVideo();
    $("body").removeClass("open-video");
  }

  $(".video-modal").on("click", function () {
    closeVideo();
  });

  $(document).on("keyup", function (e) {
    if (e.key === "Escape") {
      closeVideo();
    }
  });
}

function openExploreVideo() {
  const exploreVideoThumbs = document.querySelectorAll('section#explore .swiper-slide');
  const videos = document.querySelectorAll('.explore-video-modal');

  exploreVideoThumbs.forEach(thumb => {
    const thumbIndex = Object.values(thumb.parentElement.getElementsByClassName('swiper-slide')).indexOf(thumb);

    thumb.addEventListener('click', () => {
      videos[thumbIndex].classList.add('active');
    })

    document.querySelectorAll('.explore-video-modal .close').forEach(button => {
      button.addEventListener('click', () => {
        videos[thumbIndex].classList.remove('active');
      })
    })

    document.addEventListener('keydown', (e) => {
      if(e.key === 'Escape') {
        videos[thumbIndex].classList.remove('active');
      }
    })
  })
}

function accordion() {
  const acc = document.getElementsByClassName("accordion");
  let i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      const panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = `${panel.scrollHeight  }px`;
      }
    });
  }
}

function floatLabels() {
  $(".wpcf7-form-control:not(.wpcf7-submit)")
    .focus(function () {
      $(this).parent().parent().addClass("active");
    })
    .blur(function () {
      const cval = $(this).val();
      if (cval.length < 1) {
        $(this).parent().parent().removeClass("active");
      }
    });
}

function modalTarget() {
  $("[data-modal-target]").on("click", function () {
    const target = $(this).data("modal-target");
    $(`[data-modal=${  target  }]`).addClass("active");
  });

  $(".modal .close").on("click", function () {
    $(".modal").removeClass("active");
  });

  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      $(".modal").removeClass("active");
    }
  });
}

export { toggleNav, openVideo, accordion, floatLabels, modalTarget, openExploreVideo };
