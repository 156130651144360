import $ from "jquery";

function textSplit() {
  $("[data-split]").each(function () {
    var text = $(this).html();
    var arrayText = text.split(" ");
    var htmlText = "";
    for (var i = 0; i < arrayText.length; i++) {
      var tempText = arrayText[i];
      var time = i * 5;
      htmlText +=
        '<span class="word"><span>' + tempText + "&nbsp;</span></span>";
    }
    $(this).html(htmlText);
  });
}

export default textSplit;
