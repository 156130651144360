import jQuery from "jquery";
import { gsap } from "gsap";
import ImagesLoaded from "@utils/imagesloaded";
import { createWidget } from '@typeform/embed'

const siteTransitionMask = jQuery(".site-transition__mask");
const siteTransition = jQuery("[data-site-transition]");

class OverlayTransition {
  constructor() {
    this.name = "overlay-transition";

    this._imagesLoaded = false;
    this._imgLoader = null;
    this._onImagesLoaded = this._onImagesLoaded;
  }

  async beforeLeave({ next }) {
    this._imagesLoaded = false;
    // if next.container is not loaded, try again during `beforeEnter` hook.
    if (!next.container) return;
    // preload images from next container during leave transition
    // do not wait to images preloading to finish to start leave transition
    this._imgLoader = new ImagesLoaded(next.container, this._onImagesLoaded);
  }

  async beforeEnter(data) {
    // ScrollTrigger.getAll().forEach((t) => t.kill());
    // this.initCF7(data.next.container);

    // if images are loaded, skip here
    if (this._imagesLoaded === true || !this._imagesLoaded) return;

    return new Promise(resolve => {
      // if imagesLoaded has not been initialized, because next.container was null in `beforeLeave` hook.
      if (!this._imgLoader)
        this._imgLoader = new ImagesLoaded(
          data.next.container,
          this._onImagesLoaded,
        );

      // wait until images are loaded
      this._imgLoader.once("always", resolve);
    });
  }

  async afterEnter(data) {
    if (document.getElementById('typeform')) {
      var form = document.getElementById('typeform');

      const id = form.dataset.tfWidget;
      const options = {
        container: document.querySelector('#typeform'),
        inlineOnMobile: false,
        medium: "snippet",
      }

      createWidget(id, options)
      
      // console.log(tf);

      // tf.load();
    }
  }

  async leave(data) {
    this.transitionIn(data.current.container);
    await this.delay(1000);
    data.current.container.remove();
  }

  async enter(data) {
    this.transitionOut(data.next.container);
  }

  transitionIn({ container }) {
    const tl = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "power2.inOut",
      },
    });
    tl.set(siteTransition, { autoAlpha: 1 })
      .fromTo(siteTransition, { yPercent: -100 }, { yPercent: 0 })
      .fromTo(siteTransitionMask, { yPercent: 80 }, { yPercent: 0 }, 0);
    return tl;
  }

  transitionOut({ container }) {
    const tl = gsap.timeline({
      defaults: {
        duration: 1.1,
        ease: "power2.inOut",
      },
      onStart: async () => {
        await this.initScript();
      },
    });
    tl.to(siteTransition, { yPercent: 100 }).to(
      siteTransitionMask,
      { yPercent: -80 },
      0,
    );
    return tl;
  }

  _onImagesLoaded() {
    this._imagesLoaded = true;

    this._imgLoader.destroy();
    this._imgLoader = null;
  }

  delay(n) {
    n = n || 2000;
    return new Promise(resolve => {
      setTimeout(resolve, n);
      console.log("delay ok");
    });
  }

  initScript() {
    console.log("initScript na overlayTransition");
    console.log("initScript na overlayTransition ending");
  }
}

export default OverlayTransition;
